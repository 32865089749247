import React from 'react';
import BlogNewsroom from 'views/BlogNewsroom';

const BlogNewsroomPage = () => {
  return <BlogNewsroom />;
};

export default BlogNewsroomPage;
// import React from 'react';

// import { PickAndSpeak2024 } from '../views';

// const BlogNewsroomPage = () => {

// return <PickAndSpeak2024/>
// };

// export default BlogNewsroomPage;